import {
  useSearchParams,
  useParams,
  Link,
  useNavigate,
} from "react-router-dom";
import RightPage from "../../Components/RightPage";
import { useContext, useEffect, useState } from "react";
import ThemeCont from "../../Components/Theme";
import "animate.css";
import { Theme } from "../../Contexts/Theme";
import { routes } from "../../routes";
import loop from "../../Icons/Loop.svg";
import robot from "../Main/img/robot.png";
import stars from "./img/stars.svg";
import i1 from "../../Components/Card/img/1.svg";
import i2 from "../../Components/Card/img/2.svg";
import i3 from "../../Components/Card/img/3.svg";
import i4 from "../../Components/Card/img/4.svg";
import { Pagination } from "swiper/modules";
import busket from "../../Icons/Bag.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "./style.css";
import { Fade, Slide } from "react-reveal";
import ReactImageMagnify from "react-image-magnify";
import Zoom from "react-img-zoom";
import { Store } from "react-notifications-component";
import { useRef } from "react";
import LooksLike from "../../Components/LookLike";
import ava from "./img/ava.webp";
import b1 from "./img/1.png";
import b2 from "./img/2.png";
import b3 from "./img/3.png";
import b4 from "./img/4.png";
import { getRandomInt } from "../../Api/ApiGet";
import { BusketV } from "../../Contexts/Busket";
const arrs = [b1, b2, b3, b4];
export default function CardPage() {
  const Navigate = useNavigate();
  const { myParam } = useParams();
  const [theme, setTheme] = useContext(Theme);
  const [card, setCard] = useState();
  const [imgs, setImgs] = useState([]);
  const [text, setText] = useState(false);
  const [busketArr, setBusket] = useContext(BusketV);
  const [currentImage, setCurrentImage] = useState({
    image: "",
    index: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [myParam]);
  const [openbig, isOpenBig] = useState(false);
  const image = useRef(null);
  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  const getInfo = () => {
    const formData = new FormData();
    formData.append("id", myParam);
    fetch("https://comeback-team.ru/admin/TovarCard.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCard(data[0]);
        setImgs(data[0].Photo.split(" "));
        setCurrentImage({ image: data[0].Photo.split(" ")[0], index: 0 });
        document.getElementsByClassName("scroll")[0].scrollTo(0, 0);
      });
  };
  useEffect(() => {
    getInfo();
  }, [myParam]);
  const notification = (titlet, text) => {
    Store.addNotification({
      title: titlet,
      message: text,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 2500,
        onScreen: true,
      },
    });
  };
  return (
    <div className="cardPage">
      {openbig && (
        <div
          className="bis"
          id="close"
          onClick={(e) => e.target.id == "close" && isOpenBig(false)}
        >
          <div
            className="bisLeft"
            onClick={() =>
              currentImage &&
              currentImage.index &&
              currentImage.index != 0 &&
              setCurrentImage({
                image: imgs[currentImage.index - 1],
                index: currentImage.index - 1,
              })
            }
          >
            ▶
          </div>
          <img src={currentImage.image} alt="" className="bigSize" />
          <div
            className="bisRight"
            onClick={() => {
              currentImage &&
                currentImage.index != imgs.length - 1 &&
                setCurrentImage({
                  image: imgs[currentImage.index + 1],
                  index: currentImage.index + 1,
                });
            }}
          >
            ▶
          </div>
        </div>
      )}
      <div className="cardPageBody">
        <div className="headerMain searchInput">
          <ThemeCont></ThemeCont>
          <div
            className="loopInput"
            style={{ background: "var(--background-input)" }}
          >
            <input type="text" placeholder="Поиск..." />
            <img src={loop} alt="" />
          </div>
          <Link to={routes.services} className="robot">
            <img src={robot} alt="" /> Ai ассистент
          </Link>
          {document.body.clientWidth > 1500 && (
            <Link to={routes.services}>Перейти в Каталог</Link>
          )}
        </div>
        <div className="scroll">
          <div className="cardPageInfo">
            {document.body.clientWidth > 640 ? (
              <Slide left duration={500}>
                <div className="cardInfoImg">
                  <div className="cardMainImg">
                    <img
                      src={currentImage && currentImage.image}
                      alt=""
                      ref={image}
                      onClick={() => isOpenBig(true)}
                    />
                    {/*    {imgs && imgs[0] && (
                  <Zoom
                    img={imgs[0]}
                    zoomScale={2}
                    width={
                      document.getElementsByClassName("cardInfoImg")[0]
                        .offsetWidth
                    }
                  />
                )} */}
                  </div>
                  <div className="smallImgCard">
                    {imgs &&
                      imgs.map((em, index) => {
                        return (
                          <img
                            style={
                              em == currentImage.image
                                ? { border: "2px solid var(--color-text-one)" }
                                : {}
                            }
                            onClick={() =>
                              setCurrentImage({
                                image: em,
                                index: index,
                              })
                            }
                            src={em}
                            alt=""
                          />
                        );
                      })}
                  </div>
                </div>{" "}
              </Slide>
            ) : (
              <Fade>
                <div className="izb">
                  <p
                    className="bread"
                    onClick={() => Navigate(routes.services)}
                  >
                    Каталог<span>&gt;</span>
                    <b>{(card && card.Description) || ""}</b>
                  </p>
                  <div
                    className="opButton"
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.12681 13.8076C3.79429 14.0904 3.28889 14.0577 2.99794 13.7344L0.197934 10.6233C-0.065978 10.3301 -0.065978 9.89218 0.197934 9.59896L2.99794 6.48785C3.28889 6.16452 3.79429 6.13178 4.12681 6.41466C4.45931 6.69753 4.49301 7.18893 4.20206 7.51218L2.56301 9.33335H10.4C12.6091 9.33335 14.4 7.59221 14.4 5.44445C14.4 3.29667 12.6091 1.55556 10.4 1.55556H3.6C3.15817 1.55556 2.8 1.20733 2.8 0.777778C2.8 0.348219 3.15817 0 3.6 0H10.4C13.4928 0 16 2.43756 16 5.44445C16 8.45135 13.4928 10.8889 10.4 10.8889H2.56301L4.20206 12.7101C4.49301 13.0333 4.45931 13.5247 4.12681 13.8076Z"
                        fill="var(--color-text-two)"
                      />
                    </svg>
                  </div>
                  <div className="favouritesButton mb">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42682 19.4228C6.15148 19.4228 5.87614 19.3677 5.62833 19.2301C4.96751 18.9272 4.58203 18.2939 4.58203 17.5505V4.74713C4.58203 3.72837 5.40805 2.90234 6.42682 2.90234H15.5681C16.5869 2.90234 17.4129 3.72837 17.4129 4.74713V17.578C17.4129 18.2939 16.9999 18.9272 16.3666 19.2576C15.7058 19.5605 14.9624 19.4779 14.4117 19.0098L11.4656 16.6143C11.1902 16.3941 10.8047 16.3941 10.5294 16.6143L7.58325 19.0098C7.25284 19.2851 6.83983 19.4228 6.42682 19.4228ZM10.9975 15.3202C11.4105 15.3202 11.8235 15.4579 12.1539 15.7332L15.1001 18.1287C15.3203 18.3215 15.6232 18.349 15.8986 18.2388C16.1739 18.1012 16.3116 17.8534 16.3116 17.578V4.74713C16.3116 4.33412 15.9812 4.00371 15.5681 4.00371H6.42682C6.01381 4.00371 5.6834 4.33412 5.6834 4.74713V17.578C5.6834 17.8809 5.8486 18.1287 6.09641 18.2388C6.37175 18.3765 6.64709 18.3215 6.8949 18.1287L9.84105 15.7332C10.1715 15.4854 10.5845 15.3202 10.9975 15.3202Z"
                        fill="white"
                      />
                      <path
                        d="M13.4475 6.86308H8.60146C8.29859 6.86308 8.05078 6.61528 8.05078 6.3124C8.05078 6.00953 8.29859 5.76172 8.60146 5.76172H13.4199C13.7228 5.76172 13.9706 6.00953 13.9706 6.3124C13.9706 6.61528 13.7503 6.86308 13.4475 6.86308Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <Swiper
                  grabCursor={true}
                  effect={"creative"}
                  style={{ boxShadow: theme.shadowCard }}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ["100%", 0, 0],
                    },
                  }}
                  pagination={true}
                  modules={[EffectCreative, Pagination]}
                  className="mySwiper"
                >
                  {imgs &&
                    imgs.map((em, index) => {
                      return (
                        <SwiperSlide>
                          <img src={em} alt="" />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
                <div className="imgHots">
                  <div className="hotEms">
                    <div className="hotEm">
                      <img src={i1} alt="" />
                      Распродажа!
                    </div>
                    <div
                      className="hotEm"
                      style={{
                        background:
                          "linear-gradient(90deg, #0295FF 0%, #00C2FF 100%)",
                      }}
                    >
                      <img src={i1} alt="" />
                      1+1 бесплатно!
                    </div>
                    <div
                      className="hotEm"
                      style={{
                        background:
                          "linear-gradient(90deg, #9F02FF 0%, #FF0099 100%)",
                      }}
                    >
                      <img src={i2} alt="" />
                      Подарок к заказу!
                    </div>

                    <div
                      className="hotEm"
                      style={{
                        background:
                          "linear-gradient(90deg, #00A86B 0%, #61D36C 100%)",
                      }}
                    >
                      <img src={i3} alt="" />
                      Бесплатная доставка!
                    </div>
                    <div
                      className="hotEm"
                      style={{
                        background:
                          "linear-gradient(90deg, #D00000 0%, #FF0000 100%)",
                      }}
                    >
                      <img src={i4} alt="" />
                      Осталось {randomNumber(1, 50)} шт.!
                    </div>
                  </div>
                </div>
              </Fade>
            )}
            <Slide right duration={500}>
              <div className="cardInfoTxt">
                <div className="izb">
                  <div
                    className="opButton"
                    style={
                      document.body.clientWidth < 640 ? { display: "none" } : {}
                    }
                    onClick={() => window.history.back()}
                  >
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.12681 13.8076C3.79429 14.0904 3.28889 14.0577 2.99794 13.7344L0.197934 10.6233C-0.065978 10.3301 -0.065978 9.89218 0.197934 9.59896L2.99794 6.48785C3.28889 6.16452 3.79429 6.13178 4.12681 6.41466C4.45931 6.69753 4.49301 7.18893 4.20206 7.51218L2.56301 9.33335H10.4C12.6091 9.33335 14.4 7.59221 14.4 5.44445C14.4 3.29667 12.6091 1.55556 10.4 1.55556H3.6C3.15817 1.55556 2.8 1.20733 2.8 0.777778C2.8 0.348219 3.15817 0 3.6 0H10.4C13.4928 0 16 2.43756 16 5.44445C16 8.45135 13.4928 10.8889 10.4 10.8889H2.56301L4.20206 12.7101C4.49301 13.0333 4.45931 13.5247 4.12681 13.8076Z"
                        fill="var(--color-text-two)"
                      />
                    </svg>
                    Вернуться к каталогу
                  </div>
                  <div
                    className="favouritesButton"
                    style={
                      document.body.clientWidth < 640 ? { display: "none" } : {}
                    }
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42682 19.4228C6.15148 19.4228 5.87614 19.3677 5.62833 19.2301C4.96751 18.9272 4.58203 18.2939 4.58203 17.5505V4.74713C4.58203 3.72837 5.40805 2.90234 6.42682 2.90234H15.5681C16.5869 2.90234 17.4129 3.72837 17.4129 4.74713V17.578C17.4129 18.2939 16.9999 18.9272 16.3666 19.2576C15.7058 19.5605 14.9624 19.4779 14.4117 19.0098L11.4656 16.6143C11.1902 16.3941 10.8047 16.3941 10.5294 16.6143L7.58325 19.0098C7.25284 19.2851 6.83983 19.4228 6.42682 19.4228ZM10.9975 15.3202C11.4105 15.3202 11.8235 15.4579 12.1539 15.7332L15.1001 18.1287C15.3203 18.3215 15.6232 18.349 15.8986 18.2388C16.1739 18.1012 16.3116 17.8534 16.3116 17.578V4.74713C16.3116 4.33412 15.9812 4.00371 15.5681 4.00371H6.42682C6.01381 4.00371 5.6834 4.33412 5.6834 4.74713V17.578C5.6834 17.8809 5.8486 18.1287 6.09641 18.2388C6.37175 18.3765 6.64709 18.3215 6.8949 18.1287L9.84105 15.7332C10.1715 15.4854 10.5845 15.3202 10.9975 15.3202Z"
                        fill="var(--base-icon-color)"
                      />
                      <path
                        d="M13.4475 6.86308H8.60146C8.29859 6.86308 8.05078 6.61528 8.05078 6.3124C8.05078 6.00953 8.29859 5.76172 8.60146 5.76172H13.4199C13.7228 5.76172 13.9706 6.00953 13.9706 6.3124C13.9706 6.61528 13.7503 6.86308 13.4475 6.86308Z"
                        fill="var(--base-icon-color)"
                      />
                    </svg>
                    В избранное
                  </div>
                </div>
                {document.body.clientWidth > 640 && (
                  <p
                    className="bread"
                    onClick={() => Navigate(routes.services)}
                  >
                    Каталог<span>&gt;</span>
                    <b>{(card && card.Description) || ""}</b>
                  </p>
                )}
                <div className="cardPageTitle">
                  {(card && card.Title) || ""}
                </div>
                <div className="cardPagePrice">
                  {card &&
                    card.PriceOld != "undefined" &&
                    card.PriceOld != "" && (
                      <p className="oldPric">{card.PriceOld}</p>
                    )}
                  <p className="actualPrice">
                    {(card && card.Price + " ₽") || ""}
                  </p>
                </div>
                <div className="cardReview">
                  <img src={stars} alt="" />
                  <p>4.9 (104) отзывов</p>
                </div>
                <p className="descCard">Характеристики</p>
                <div className="desc">
                  Артикул:  AB34.5 <br />
                  Размер камня (мм):  14х19 <br />
                  Размер изделия (мм):  20х42
                  <br />
                  Материал: золото, янтарь <br />
                  Весь изделия: 35 грамм <br />
                  Наличие: в наличии <br />
                  <b>Показать полностью</b>
                </div>
                <div className="PageCardButtons">
                  <div
                    className="corzButton"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      notification(
                        "Корзина:",
                        `Товар ${card.Title} добавлен в корзину`
                      );

                      setBusket((busket) => [...busket, card]);
                    }}
                  >
                    <img src={busket} alt="" />В корзину
                  </div>
                  <div className="fastBuy">Быстрый заказ</div>
                </div>
              </div>
            </Slide>
          </div>
          <div className="footerCard">
            <h4>Описание</h4>
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    card && card.Texts != "undefined" && !text
                      ? card.Texts.substr(0, 350) + "..."
                      : card && card.Texts,
                }}
              ></div>
              <b
                style={
                  text
                    ? { display: "none" }
                    : { color: "var(--color-text-one)", cursor: "pointer" }
                }
                onClick={() => setText(true)}
              >
                Показать полностью
              </b>
            </p>
          </div>

          {document.body.clientWidth < 640 && (
            <div className="banners">
              <img src={arrs[getRandomInt(0, 3)]} alt="" />
            </div>
          )}
          <div className="reviews">
            <h4>Отзывы</h4>
            <div className="reviewItem">
              <img src={ava} alt="" />
              <article>
                <div className="reviewTitle">
                  <p>Людмила Н.</p>
                  <span>18.11.2023</span>
                  <img src={stars} alt="" />
                </div>
                <div className="prem">
                  <p>Достоинства:</p>
                  <span>
                    Все просто супер - начиная от заказа данных сережек и
                    заканчивая их доставкой. Носить одно удовольствие.
                  </span>
                </div>
                <div className="prem">
                  <p>Недостатки:</p>
                  <span>Не заметила.</span>
                </div>
                <div className="comment">
                  <span>
                    Долго выбирала какие все таки сережки приобрести себе на
                    лето. Хотелось чего-то такого яркого, легкого и при этом
                    чтобы выглядели элегеантно. Понравилось несколько вариантов
                    из такого большого ассортимента и в итоге я остановилась на
                    этих. Когда получила - была в восторге! Это именно то что я
                    и хотела, спасибо за такую красоту!
                  </span>
                </div>
              </article>
            </div>
          </div>
          <div className="footerCard">
            <h4>Похожие товары</h4>
            <LooksLike type={card && card.Description}></LooksLike>
          </div>
        </div>
      </div>
      <RightPage></RightPage>
    </div>
  );
}
