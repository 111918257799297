import test from "./test.png";
import test2 from "./test2.png";
import "./style.css";
import RightPage from "../../Components/RightPage";
import { routes } from "../../routes";
import { Link } from "react-router-dom";
import oz from "./img/oz.png";
import almaz from "./img/alm.png";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";
import img6 from "./img/6.png";
import all1 from "./img/11.png";
import all2 from "./img/22.png";
import all3 from "./img/33.png";
import all4 from "./img/44.png";
import gal from "./img/gal.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import big from "./img/big.png";
// import required modules
import { Pagination } from "swiper/modules";
import { Fade, Zoom } from "react-reveal";
import { useContext } from "react";
import { Theme } from "../../Contexts/Theme";
export default function Company() {
  const [theme, setTheme] = useContext(Theme);
  return (
    <div className="Page">
      <div className="companyBody">
        <h2>О КОМПАНИИ</h2>
        <div className="flexHeight">
          <div className="gr" style={{ boxShadow: theme.shadowCard }}>
            <img src={oz} alt="" />
            <div>
              <p>
                Малахитовая шкатулка
                <img src={almaz} alt="" />
              </p>
              <span>
                Это молодой, но амбициозный бренд, созданный командой
                профессионалов, влюбленных в янтарь. Мы верим, что янтарь – это
                не просто камень, а настоящее произведение искусства, созданное
                самой природой.
              </span>
            </div>
          </div>
          <h3>О продукте</h3>

          <div className="aboutProduct">
            <b>
              <span>Янтарь:</span> 40 миллионов лет, отпечатанных в камне.{" "}
              {document.body.clientWidth > 640 && <br />}
            </b>
            Именно столько понадобилось, чтобы стать вашим идеальным украшением.
          </div>
          <Swiper
            slidesPerView={document.body.clientWidth > 640 ? 3 : 1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={img1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img3} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img4} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img5} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img6} alt="" />
            </SwiperSlide>
          </Swiper>
          <Zoom duration={500}>
            <img src={big} className="bigComp" alt="" />
          </Zoom>
          <h2>Наши ценности</h2>
          {document.body.clientWidth < 640 ? (
            <Swiper
              slidesPerView={document.body.clientWidth > 640 ? 3 : 1}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="cennEm">
                  <img src={all1} alt="" />
                  <p>Качество</p>
                  <span>
                    Используем только высококачественные материалы и фурнитуру
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="cennEm">
                  <img src={all2} alt="" />
                  <p>Стиль</p>
                  <span>
                    Следим за модными трендами и предлагаем актуальные украшения
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="cennEm">
                  <img src={all3} alt="" />
                  <p>Доступность</p>
                  <span>
                    Хотим, чтобы красота янтаря была доступной для каждого
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="cennEm">
                  <img src={all4} alt="" />
                  <p>Индивидуальность</p>
                  <span>
                    Ценим наших клиентов и предлагаем уникальные украшения
                  </span>
                </div>
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="cenn">
              <div className="cennEm">
                <img src={all1} alt="" />
                <p>Качество</p>
                <span>
                  Используем только высококачественные материалы и фурнитуру
                </span>
              </div>
              <div className="cennEm">
                <img src={all2} alt="" />
                <p>Стиль</p>
                <span>
                  Следим за модными трендами и предлагаем актуальные украшения
                </span>
              </div>
              <div className="cennEm">
                <img src={all3} alt="" />
                <p>Доступность</p>
                <span>
                  Хотим, чтобы красота янтаря была доступной для каждого
                </span>
              </div>
              <div className="cennEm">
                <img src={all4} alt="" />
                <p>Индивидуальность</p>
                <span>
                  Ценим наших клиентов и предлагаем уникальные украшения
                </span>
              </div>
            </div>
          )}
          <ul className="gals">
            <Fade left duration={500} delay={0}>
              <li>
                <img src={gal} alt="" />
                <p>Предлагаем гибкую систему скидок для постоянных клиентов</p>
              </li>
            </Fade>
            <Fade left duration={500} delay={100}>
              <li>
                <img src={gal} alt="" />
                <p>Вы можете заказать индивидуальное изготовление украшений</p>
              </li>
            </Fade>
            <Fade left duration={500} delay={200}>
              <li>
                <img src={gal} alt="" />
                <p>Регулярно проводим акции и розыгрыши</p>
              </li>
            </Fade>
          </ul>
          <Fade duration={500} bottom>
            <Link to={routes.services} className="button">
              Перейти в каталог
            </Link>
          </Fade>
          <p className="txtCom">
            <b>Мы всегда рады</b> новым клиентам и партнерам!
          </p>
        </div>
      </div>
      <RightPage></RightPage>
    </div>
  );
}
