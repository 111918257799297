import RightPage from "../../Components/RightPage";
import "./style.css";
export default function ArModel() {
  return (
    <div className="modelView">
      <model-viewer
        src="/ar/gold_ring_for_female.glb"
        ar
        ar-modes="webxr scene-viewer quick-look"
        camera-controls
        tone-mapping="neutral"
        poster="/ar/poster.webp"
        shadow-intensity="0"
        environment-image="/ar/pillars_1k.hdr"
        skybox-image="/ar/pillars_1k.hdr"
      >
        <div class="progress-bar hide" slot="progress-bar">
          <div class="update-bar"></div>
        </div>
        <button slot="ar-button" id="ar-button">
          Посмотреть в AR
        </button>
      </model-viewer>
      <RightPage></RightPage>
    </div>
  );
}
