import "./style.css";
import i1a from "./img/1a.svg";
import i2a from "./img/2a.svg";
import i3a from "./img/3a.svg";
import i4a from "./img/4a.svg";
import i5a from "./img/5a.svg";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
import i4 from "./img/4.svg";
import i5 from "./img/5.svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useContext, useState } from "react";
import { BusketV } from "../../Contexts/Busket";
import { User } from "../../Contexts/User";
export default function NavMobile() {
  const [user, setUser] = useContext(User);
  console.log(user);
  const Navigate = useNavigate();
  const location = useLocation();
  const [busket, setBusket] = useContext(BusketV);
  return (
    <div className="MobileNav">
      <Link className="navItem" to={routes.main}>
        <img src={location.pathname == routes.main ? i1a : i1} alt="" />
        <p style={location.pathname == routes.main ? { color: "#FF7A00" } : {}}>
          Главная
        </p>
      </Link>
      <Link className="navItem" to={routes.services}>
        <img src={location.pathname == routes.services ? i2a : i2} alt="" />
        <p
          style={
            location.pathname == routes.services ? { color: "#FF7A00" } : {}
          }
        >
          Каталог
        </p>
      </Link>

      <Link className="navItem" to={routes.basket}>
        <img src={location.pathname == routes.basket ? i3a : i3} alt="" />
        <p
          style={location.pathname == routes.basket ? { color: "#FF7A00" } : {}}
        >
          Корзина
        </p>
        {busket && busket.length > 0 && (
          <span className="numLength">{busket.length}</span>
        )}
      </Link>

      <Link className="navItem" to={routes.chat}>
        <img src={location.pathname == routes.chat ? i5a : i5} alt="" />
        <p style={location.pathname == routes.chat ? { color: "#FF7A00" } : {}}>
          On-line Чат
        </p>
      </Link>
      <Link
        className="navItem"
        to={user.length == 0 ? routes.login : routes.profile}
      >
        <img src={i4} alt="" />
        <p>Профиль</p>
      </Link>
    </div>
  );
}
