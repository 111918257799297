import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "./style.css";
import test from "./1.png";
import i2 from "./2.png";
import i3 from "./3.png";
import gal from "./gal.svg";
import { Pagination } from "swiper/modules";
import soc from "./soc.svg";
import { useContext, useRef, useState } from "react";
import vk from "./vk.svg";
import tg from "./tg.svg";
import arrow from "./arrow.svg";
import prom from "./prom.svg";
import { Theme } from "../../Contexts/Theme";
import nig from "./n.svg";
import tur from "./tur.png";
import turT from "./turtxt.svg";
import playB from "./play.svg";
import YouTube from "react-youtube";
import ListRightPage from "../ListRightPage";
import { Store } from "react-notifications-component";

export default function RightPage() {
  const promo = useRef(null);
  function copy() {
    promo.current.select();
    document.execCommand("Copy");
    Store.addNotification({
      title: "Промокод:",
      message: `Промокод ${promo.current.value} успешно скопирован`,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 2500,
        onScreen: true,
      },
    });
  }
  const [theme, setTheme] = useContext(Theme);
  const [slide, setSlide] = useState(true);
  return (
    <div className="rightBody">
      <input
        type="text"
        id="prom"
        ref={promo}
        value={"XFASHION"}
        style={{ position: "absolute", top: "-100vw", right: "-100vw" }}
      />
      <div className="counters">
        <div
          onClick={() => setSlide(true)}
          style={
            slide ? { background: "var(--color-text-two)", color: "white" } : {}
          }
        >
          1
        </div>
        <div
          onClick={() => setSlide(false)}
          style={
            !slide
              ? { background: "var(--color-text-two)", color: "white" }
              : {}
          }
        >
          2
        </div>
      </div>
      <div
        className="firstBodyRight"
        style={{ transform: slide ? "translateX(0)" : "translateX(-19.5vw)" }}
      >
        <Swiper
          grabCursor={true}
          effect={"creative"}
          style={{ boxShadow: theme.shadowCard }}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          pagination={true}
          modules={[EffectCreative, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="sl">
              <img src={test} alt="" />

              <label
                htmlFor="prom"
                onClick={(e) => {
                  e.preventDefault();
                  copy();
                }}
              >
                <img src={prom} className="prom" alt="" />
              </label>
              <p>Распродажа</p>
              <span>10% скидка по промокоду до 31 марта</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="sl">
              <img src={i2} alt="" />
              <label
                htmlFor="prom"
                onClick={(e) => {
                  e.preventDefault();
                  copy();
                }}
              >
                <img src={prom} className="prom" alt="" />
              </label>
              <p>Распродажа</p>
              <span>10% скидка по промокоду до 31 марта</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="sl">
              <img src={i3} alt="" />
              <label
                htmlFor="prom"
                onClick={(e) => {
                  e.preventDefault();
                  copy();
                }}
              >
                <img src={prom} className="prom" alt="" />
              </label>
              <p>Распродажа</p>
              <span>10% скидка по промокоду до 31 марта</span>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="social" style={{ boxShadow: theme.shadowCard }}>
          <img src={theme == "white" ? soc : nig} alt="" />
        </div>
        <div
          className="rightForm"
          style={{
            boxShadow: theme.shadowCard,
            background: "var(--background-form)",
          }}
        >
          <h4 style={{ color: "var(--color-text-one)" }}>
            Перезвоним вам за 1 минуту
          </h4>
          <p style={{ color: "var(--color-text-one)" }}>
            Сориентируем по любому вопросу
          </p>
          <input type="text" placeholder="Ваше имя" />
          <input type="tel" placeholder="Телефон*" />
          <div
            className="formButton"
            onClick={() => {
              Store.addNotification({
                title: "Заявка на звонок",
                message: "Ваш запрос получен, перезвоним в течение 5ти минут",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 2500,
                  onScreen: true,
                },
              });
            }}
          >
            Перезвоните мне
          </div>
          <div className="agree">
            <img src={gal} alt="" />
            <p>
              Согласен с <a href="#">Политикой конфиденциальности</a>
            </p>
          </div>
        </div>
      </div>
      <div
        className="secondBodyRight"
        style={{ transform: slide ? "translateX(0)" : "translateX(-19.5vw)" }}
      >
        <div className="tur">
          <img src={tur} className="turImg" alt="" />
          <img src={turT} className="turtxt" alt="" />
          <img src={playB} className="turPlay" alt="" />
        </div>
        <a
          className="socialLink"
          href="https://vk.com/club225084983"
          target="_blank"
        >
          <img src={vk} alt="" />
          <div>
            <p>
              RolexTeam
              <img src={arrow} alt="" />
            </p>
            <span>
              Задайте нам вопрос в личные сообщения <br /> и следить за акциями
              и новостями.
            </span>
          </div>
        </a>

        <a
          className="socialLink"
          href="https://t.me/internetvitrina_bot"
          target="_blank"
        >
          <img src={tg} alt="" />
          <div>
            <p>
              t.me/RolexTeam
              <img src={arrow} alt="" />
            </p>
            <span>
              Наши работы, акции, оперативные <br /> ответы на ваши возникшие
              вопросы
            </span>
          </div>
        </a>
        {slide == false && (
          <YouTube videoId="Xhu4eWfTkM0" className="youtubeRight" />
        )}
        <ListRightPage />
      </div>
    </div>
  );
}
