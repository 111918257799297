import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { id } from "../Consts/Const";

export async function getData() {
  let list = [];
  fetch("https://comeback-team.ru/admin/Tovar.php")
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      list = data;
    });
  return list;
}

//Авторизация
export async function login(tel, password) {
  let form = new FormData();
  form.append("tel", tel);
  form.append("password", password);
  const response = await fetch("https://comeback-team.ru/showcase/auth.php", {
    method: "POST",
    body: form,
  });

  return response.json();
}

//История заказов

export async function getHistoryOrders(tel) {
  let form = new FormData();
  form.append("tel", tel);
  form.append("id", id);
  const response = await fetch("https://comeback-team.ru/getOrdersByTel.php", {
    method: "POST",
    body: form,
  });

  return response.json();
}

//Получение информации о карточке товара

export async function getCard(idCard) {
  let form = new FormData();
  form.append("ID", idCard);
  const response = await fetch("https://comeback-team.ru/getCard.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Получение сообщений из чата

export async function getChat() {
  let form = new FormData();
  form.append("idOwner", id);
  form.append(
    "idUser",
    localStorage.user ? JSON.parse(localStorage.user).ID : id + 245
  );
  const response = await fetch(
    "https://comeback-team.ru/showcase/getChat.php",
    {
      method: "POST",
      body: form,
    }
  );
  return response.json();
}

//Отправка сообщения

export async function sendMessage(text) {
  let form = new FormData();
  form.append("idOwner", id);
  form.append("time", new Date().toString());
  form.append("text", text);
  form.append(
    "idUser",
    localStorage.user ? JSON.parse(localStorage.user).ID : id + 245
  );
  const response = await fetch(
    "https://comeback-team.ru/showcase/sendMessage.php",
    {
      method: "POST",
      body: form,
    }
  );
  return response.status;
}

//Регистрация

export async function RegistrationUser(formReg) {
  let form = new FormData();
  form.append("name", formReg.name);
  form.append("date", new Date().toString());
  form.append("tel", formReg.tel);
  form.append("password", formReg.password);
  form.append("email", formReg.email);
  form.append("idComp", id);
  const response = await fetch(
    "https://comeback-team.ru/showcase/registration.php",
    {
      method: "POST",
      body: form,
    }
  );
  return response.status;
}

//Обновление аватара

export async function uploadAvatar(file) {
  let form = new FormData();
  form.append("file", file);

  let formData = new FormData();
  formData.append("id", JSON.parse(localStorage.user).ID);
  const response = await fetch("https://comeback-team.ru/avatar.php", {
    method: "POST",
    body: form,
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      formData.append("path", data);
    });

  const response2 = await fetch(
    "https://comeback-team.ru/showcase/updateImage.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response2.json();
}

//Получение списка тем

export async function getThemes() {
  let form = new FormData();
  form.append("id", id);
  const response = await fetch(
    "https://comeback-team.ru/showcase/Themes/getThemes.php",
    {
      method: "POST",
      body: form,
    }
  );

  return await response.json();
}

//Рандом

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
