import "./style.css";
import bag from "../../Icons/Bag.svg";
import { useState } from "react";
import { useContext } from "react";
import { Theme } from "../../Contexts/Theme";
import { Nav } from "../../Contexts/Nav";
import { BusketV } from "../../Contexts/Busket";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { Fade, Flip, Zoom } from "react-reveal";
import ReactImageMagnify from "react-image-magnify";
import deleteB from "./delete.svg";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
import i4 from "./img/4.svg";
import { Store } from "react-notifications-component";
import like from "./img/likePass.svg";
import likeA from "./img/likeAct.svg";
import { Favourite } from "../../Contexts/Favourite";
export default function Card({ cardOptions, arrs }) {
  const [theme, setTheme] = useContext(Theme);
  const [nav, setNav] = useContext(Nav);
  const [busket, setBusket] = useContext(BusketV);
  const [favourite, setFavourite] = useContext(Favourite);
  const [corz, isCorz] = useState(false);
  const [liked, setLiked] = useState(false);
  const Navigate = useNavigate();
  const imgArr = cardOptions.Photo.split(" ");
  useEffect(() => {
    favourite.find((i) => i === cardOptions.ID)
      ? setLiked(true)
      : setLiked(false);
  }, [favourite]);
  const location = useLocation();
  const notification = (titlet, text) => {
    Store.addNotification({
      title: titlet,
      message: text,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 2500,
        onScreen: true,
      },
    });
  };
  useEffect(() => {
    if (busket.length > 0) {
      let f = busket.find((em) => em.ID == cardOptions.ID);
      if (!f) {
        isCorz(false);
      } else {
        isCorz(true);
      }
    } else {
      isCorz(false);
    }
  }, [busket]);
  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  return (
    <Link
      to={`/card/${cardOptions.ID}`}
      className="card b-block"
      style={{
        width: "23%",
      }}
    >
      {/* "23%" : "31%" */}
      <Fade top duration={500}>
        <div className="b-block__content">
          <div
            className="like"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (liked) {
                setFavourite((oldArray) =>
                  oldArray.filter((item) => item !== cardOptions.ID)
                );
                notification(
                  "Избранное:",
                  `Товар ${cardOptions.Title} удален из избранного`
                );
              } else {
                setFavourite((oldArray) => [...oldArray, cardOptions.ID]);
                notification(
                  "Избранное:",
                  `Товар ${cardOptions.Title} добавлен в избранное`
                );
              }
            }}
          >
            <img src={liked ? likeA : like} alt="" />
          </div>
          {document.body.clientWidth > 640 && arrs && arrs.length > 0 ? (
            <div className="imgHots">
              <img src={imgArr[0] || ""} alt="" />

              <div className="hotEms">
                {arrs[0].find((em) => em == cardOptions.ID) && (
                  <div className="hotEm">
                    <img src={i1} alt="" />
                    Распродажа!
                  </div>
                )}
                {arrs[1].find((em) => em == cardOptions.ID) && (
                  <div
                    className="hotEm"
                    style={{
                      background:
                        "linear-gradient(90deg, #0295FF 0%, #00C2FF 100%)",
                    }}
                  >
                    <img src={i1} alt="" />
                    1+1 бесплатно!
                  </div>
                )}
                {arrs[2].find((em) => em == cardOptions.ID) && (
                  <div
                    className="hotEm"
                    style={{
                      background:
                        "linear-gradient(90deg, #9F02FF 0%, #FF0099 100%)",
                    }}
                  >
                    <img src={i2} alt="" />
                    Подарок к заказу!
                  </div>
                )}
                {arrs[3].find((em) => em == cardOptions.ID) && (
                  <div
                    className="hotEm"
                    style={{
                      background:
                        "linear-gradient(90deg, #00A86B 0%, #61D36C 100%)",
                    }}
                  >
                    <img src={i3} alt="" />
                    Бесплатная доставка!
                  </div>
                )}
                {arrs[4].find((em) => em == cardOptions.ID) && (
                  <div
                    className="hotEm"
                    style={{
                      background:
                        "linear-gradient(90deg, #D00000 0%, #FF0000 100%)",
                    }}
                  >
                    <img src={i4} alt="" />
                    Осталось {cardOptions.ID} шт.!
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="imgHots">
              <img src={imgArr[0] || ""} alt="" />
            </div>
          )}
          <p>{cardOptions.Title || ""}</p>
          <div className="price">
            {cardOptions.Price || ""} ₽
            {cardOptions.PriceOld != "undefined" &&
              cardOptions.PriceOld != "" && (
                <div className="oldPrice">{cardOptions.PriceOld} ₽</div>
              )}
            {cardOptions.PriceOld != "undefined" &&
              cardOptions.PriceOld != "" && (
                <div className="sale">
                  -
                  {(
                    ((Number(cardOptions.PriceOld) -
                      Number(cardOptions.Price)) /
                      Number(cardOptions.PriceOld)) *
                    100
                  ).toFixed(0)}
                  %
                </div>
              )}
          </div>
          <div className="cardButtons">
            <div
              className="basketButton"
              style={{
                background: corz
                  ? "var(--button-disabled-background-card)"
                  : "var(--card-button-color)",
                color: corz ? "var(--button-disabled-color-card)" : "white",
                pointerEvents:
                  corz && document.body.clientWidth > 640 ? "none" : "",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!corz) {
                  notification(
                    "Корзина:",
                    `Товар ${cardOptions.Title} добавлен в корзину`
                  );

                  setBusket((busket) => [...busket, cardOptions]);
                } else {
                  setBusket((l) =>
                    l.filter((item) => item.ID !== cardOptions.ID)
                  );
                }
              }}
            >
              {!corz && <img src={bag} alt="" />}
              {corz
                ? document.body.clientWidth > 640
                  ? "В корзине"
                  : "Убрать"
                : "В корзину"}
              {corz && document.body.clientWidth < 640 && (
                <svg
                  style={{ marginLeft: "5px" }}
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1673 2.5H0.833984M11.056 4.16667L10.7493 8.76667C10.6313 10.536 10.5727 11.4207 9.99598 11.96C9.41932 12.5 8.53198 12.5 6.75865 12.5H6.24265C4.46932 12.5 3.58198 12.5 3.00532 11.96C2.42865 11.4207 2.36932 10.536 2.25198 8.76667L1.94532 4.16667M4.83398 5.83333L5.16732 9.16667M8.16732 5.83333L7.83398 9.16667"
                    stroke="#A67345"
                    stroke-linecap="round"
                  />
                  <path
                    d="M2.83398 2.5H2.90732C3.17562 2.49314 3.4356 2.40548 3.65327 2.24847C3.87093 2.09147 4.03615 1.87243 4.12732 1.62L4.14998 1.55133L4.21465 1.35733C4.26998 1.19133 4.29798 1.10867 4.33465 1.038C4.40679 0.899593 4.51032 0.779994 4.63697 0.688773C4.76361 0.597551 4.90985 0.53724 5.06398 0.512667C5.14198 0.5 5.22932 0.5 5.40398 0.5H7.59732C7.77198 0.5 7.85932 0.5 7.93732 0.512667C8.09145 0.53724 8.23769 0.597551 8.36433 0.688773C8.49098 0.779994 8.59451 0.899593 8.66665 1.038C8.70332 1.10867 8.73132 1.19133 8.78665 1.35733L8.85132 1.55133C8.9358 1.83218 9.11053 2.07734 9.34844 2.24884C9.58635 2.42034 9.87416 2.50862 10.1673 2.5"
                    stroke="#A67345"
                  />
                </svg>
              )}
            </div>
            {corz && document.body.clientWidth > 640 && (
              <Fade right duration={300}>
                <img
                  className="deleteCard"
                  src={deleteB}
                  onClick={(e) => {
                    e.preventDefault();

                    setBusket((l) =>
                      l.filter((item) => item.ID !== cardOptions.ID)
                    );
                    notification(
                      "Корзина:",
                      `Товар ${cardOptions.Title} удален из корзины`
                    );
                  }}
                ></img>
              </Fade>
            )}
          </div>
        </div>
      </Fade>
    </Link>
  );
}
