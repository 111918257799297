import "./style.css";
import arrow from "./img/arr.svg";
import logo from "./img/logo.png";
import { useContext, useState } from "react";
import ReactInputMask from "react-input-mask";
import { RegistrationUser, login } from "../../Api/ApiGet";
import { useNavigate } from "react-router-dom";
import { User } from "../../Contexts/User";
import { routes } from "../../routes";
import { Store } from "react-notifications-component";
import { Fade } from "react-reveal";
export function Registration() {
  const Navigate = useNavigate();

  const [userData, setUserData] = useContext(User);
  const [form, setForm] = useState({
    name: "",
    tel: "",
    email: "",
    password: "",
    passwordRep: "",
  });

  const [error, setError] = useState("");
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  function writeError(text) {
    Store.addNotification({
      title: "Ошибка",
      message: text,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  return (
    <div className="registrationPage">
      <Fade right duration={500}>
        <div className="regLeft">
          <div className="rgForm">
            <h1>Регистрация</h1>
            <p>Заполните поля ниже</p>
            <input
              type="text"
              name="name"
              value={form.name}
              placeholder="Ваше имя*"
              onChange={(e) => handleChange(e)}
            />
            <ReactInputMask
              mask="+7(999) 999 99 99"
              name="tel"
              placeholder="Телефон*"
              value={form.tel}
              onChange={(e) => handleChange(e)}
            ></ReactInputMask>
            <input
              type="email"
              name="email"
              value={form.email}
              placeholder="Email*"
              onChange={(e) => handleChange(e)}
            />
            <div className="lineInputs">
              <input
                type="password"
                name="password"
                value={form.password}
                placeholder="Пароль*"
                onChange={(e) => handleChange(e)}
              />
              <input
                type="password"
                name="passwordRep"
                value={form.passwordRep}
                placeholder="Повторите пароль*"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div
              className="register"
              style={
                form.name.length == 0 ||
                form.tel.length == 0 ||
                form.email.length == 0 ||
                form.password.length == 0 ||
                form.passwordRep.length == 0 ||
                form.password != form.passwordRep
                  ? {
                      pointerEvents: "none",
                      opacity: "0.6",
                    }
                  : {}
              }
              onClick={() => {
                if (form.password.length < 6) {
                  writeError(
                    "Длинна пароля должна быть не менее 6-ти символов"
                  );
                } else if (form.tel.includes("_")) {
                  writeError("Проверьте корректность номера телефона");
                } else if (!form.email.includes("@")) {
                  writeError("Введен некорректный email");
                } else {
                  RegistrationUser(form).then((data) => {
                    if (data == 401) {
                      writeError(
                        "Пользователь с таким номером телефона уже зарегистрирован"
                      );
                    }
                    if (data == 200) {
                      login(form.tel, form.password).then((data) => {
                        if (data != "error") {
                          setUserData(data[0]);
                          localStorage.user = JSON.stringify(data[0]);
                          Navigate(routes.main);
                        }
                      });
                    }
                  });
                }
              }}
            >
              Зарегистрироваться
            </div>
            <div className="auth" onClick={() => Navigate(routes.login)}>
              Авторизоваться
            </div>
            <div
              className="backgr"
              onClick={() => {
                window.history.back();
              }}
            >
              <svg
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.24211 7.29289C0.851587 7.68342 0.851587 8.31658 1.24211 8.70711L7.60607 15.0711C7.9966 15.4616 8.62976 15.4616 9.02029 15.0711C9.41081 14.6805 9.41081 14.0474 9.02029 13.6569L3.36343 8L9.02029 2.34314C9.41081 1.95262 9.41081 1.31946 9.02029 0.928931C8.62976 0.538407 7.9966 0.538407 7.60607 0.928931L1.24211 7.29289ZM20.9492 7L1.94922 7L1.94922 9L20.9492 9L20.9492 7Z"
                  fill="white"
                />
              </svg>
              Вернуться
            </div>
          </div>
        </div>
      </Fade>
      <Fade left duration={500}>
        <div className="regRight">
          <img src={logo} className="regLogo" alt="" />
          <h3>Добро пожаловать</h3>
          <p>в мир изысканных украшений!</p>
          <div>
            <p>Войдите, чтобы увидеть</p>
            <span>новые коллекции и эксклюзивные предложения.</span>
          </div>
          <img src={arrow} className="regArr" alt="" />
        </div>
      </Fade>
    </div>
  );
}
