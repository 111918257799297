import { useContext, useEffect, useState } from "react";
import "./style.css";
import { login } from "../../Api/ApiGet";
import { DataMain } from "../../Contexts/Data";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { User } from "../../Contexts/User";
import ReactInputMask from "react-input-mask";
import logo from "../Registration/img/logo.png";
import arrow from "../Registration/img/arr.svg";
import { Fade, Slide } from "react-reveal";
import { Store } from "react-notifications-component";

export default function Login() {
  const [userDatam, setUserData] = useContext(User);
  const [tel, setTel] = useState("");
  const Navigate = useNavigate();

  const [password, setPassword] = useState("");
  function writeError(text) {
    Store.addNotification({
      title: "Ошибка",
      message: text,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }
  return (
    <div className="auths">
      <Fade right duration={500}>
        <div className="formLeft">
          <div className="formBody">
            <h3>Авторизация</h3>
            <span>Заполните поля</span>
            <ReactInputMask
              mask="+7(999) 999 99 99"
              name="tel"
              placeholder="Телефон"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            ></ReactInputMask>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Пароль"
              name=""
              id=""
            />
            <div
              className="login"
              onClick={() =>
                login(tel, password).then((data) => {
                  if (data != "error") {
                    setUserData(data[0]);
                    localStorage.user = JSON.stringify(data[0]);
                    Navigate(routes.main);
                  } else {
                    writeError("Неверный логин / пароль");
                  }
                })
              }
              style={
                tel.length > 0 && !tel.includes("_") && password.length > 0
                  ? {}
                  : {
                      pointerEvents: "none",
                      opacity: "0.5",
                    }
              }
            >
              Авторизироваться
            </div>
            <div className="reg" onClick={() => Navigate(routes.registration)}>
              Зарегистрироваться
            </div>
            <a href="" className="ps">
              Забыли пароль?
            </a>

            <div
              className="backgr"
              onClick={() => {
                window.history.back();
              }}
            >
              <svg
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.24211 7.29289C0.851587 7.68342 0.851587 8.31658 1.24211 8.70711L7.60607 15.0711C7.9966 15.4616 8.62976 15.4616 9.02029 15.0711C9.41081 14.6805 9.41081 14.0474 9.02029 13.6569L3.36343 8L9.02029 2.34314C9.41081 1.95262 9.41081 1.31946 9.02029 0.928931C8.62976 0.538407 7.9966 0.538407 7.60607 0.928931L1.24211 7.29289ZM20.9492 7L1.94922 7L1.94922 9L20.9492 9L20.9492 7Z"
                  fill="white"
                />
              </svg>
              Вернуться
            </div>
          </div>
        </div>
      </Fade>
      <Fade left duration={500}>
        <div className="regRight">
          <img src={logo} className="regLogo" alt="" />
          <h3>Добро пожаловать</h3>
          <p>в мир изысканных украшений!</p>
          <div>
            <p>Войдите, чтобы увидеть</p>
            <span>новые коллекции и эксклюзивные предложения.</span>
          </div>
          <img src={arrow} className="regArr" alt="" />
        </div>
      </Fade>
    </div>
  );
}
